function Contact() {
  return (
    <div className="contact">
        <h1>Contact us</h1>
        <p>CLS GALLERY can be reached by:</p>

        <p><span>TEL:</span> <a href="tel:646-420-3683">646-420-3683</a></p>
        <p><span>TEL:</span> <a href="tel:646-420-3680">646-420-3680</a></p>

        <p>Email: <a href="mailto:lstern7582@aol.com">lstern7582@aol.com</a></p>
    </div>
  );
}

export default Contact;
