
function About() {
  return (
    <div className="about">
        <h1>About us</h1>
        <p>We would like to introduce you to our collection of original paintings. There are Russian, Italian, French, Greek and other painters. We have been collecting those paintings for the last 35 years. Most paintings were bought directly from painters, some of the paintings were aquired from Nechemia Glezer Gallery, others were bought from Sotheby, Christy and private collectors.</p>
    </div>
  );
}

export default About;
