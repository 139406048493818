import { Link, useParams } from 'react-router-dom'
import { useState } from 'react'
function Artpiece(props) {
  const { art, artists } = props
  const params = useParams()
  const [zoomed, setZoomed] = useState(false)
  const [morezoom, setMoreZoom] = useState('')


  const renderArtInfo = (artID) => {
    let artpiece = art.filter(item => {
      return item.id === parseInt(artID, 10)
    })
    artpiece = artpiece[0]
    let artist = artists.filter(item => {
      return item.id === parseInt(artpiece.artist_id, 10)
    })
    artist = artist[0]
    return (
      <div className='display-art'>
        <h1>{artist.name}</h1>
        <Link to={`/artists/${artist.name}/${artist.id}`} className='back-btn'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M177.5 98c-8.8-3.8-19-2-26 4.6l-144 136C2.7 243.1 0 249.4 0 256s2.7 12.9 7.5 17.4l144 136c7 6.6 17.2 8.4 26 4.6s14.5-12.5 14.5-22l0-88 288 0c17.7 0 32-14.3 32-32l0-32c0-17.7-14.3-32-32-32l-288 0 0-88c0-9.6-5.7-18.2-14.5-22z" /></svg>{`Back to ${artist.name} work`}</Link>
        <div className='img-container'>
          <span>Click image to zoom</span>
          <img alt={artpiece.name} src={require(`../assets/art-big/${artpiece.image}`)} onClick={() => setZoomed(true)} />
          <div className='info-text'>
            <p>{artist.nationality}</p>
            <p>{`"${artpiece.title}"`}</p>
            <p>{artpiece.year}</p>
            <p>{artpiece.width} in. x {artpiece.height} in.</p>
          </div>

        </div>
      </div>
    )

  }

  const renderZoomedImage = (artID) => {
    let artpiece = art.filter(item => {
      return item.id === parseInt(artID, 10)
    })
    artpiece = artpiece[0]
    let artist = artists.filter(item => {
      return item.id === parseInt(artpiece.artist_id, 10)
    })
    artist = artist[0]
    return (
      <div className='full-modal' onClick={(e) => {
        if(e.target.nodeName === 'IMG' || e.target.nodeName === "DIV"){
          setZoomed(false)
          setMoreZoom('')
        }
        
        }}>
        <img className={`${morezoom}`} alt={artpiece.name} src={require(`../assets/art-big/${artpiece.image}`)}></img>
        <button onClick={() => {
          console.log(morezoom)
          if(morezoom === ''){
            setMoreZoom('morezoom')
          }else{
            setMoreZoom('')
          }
        }}>
          {
            !morezoom
            ?
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM184 296c0 13.3 10.7 24 24 24s24-10.7 24-24V232h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H232V120c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z"/></svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM136 184c-13.3 0-24 10.7-24 24s10.7 24 24 24H280c13.3 0 24-10.7 24-24s-10.7-24-24-24H136z"/></svg>
          }
          </button>
      </div>
    )
  }

  return (
    <div className="art-piece">
      {art.length === 0 || artists.length === 0 ? null : renderArtInfo(params.id)}
      {zoomed ? renderZoomedImage(params.id) : null}
    </div>
  );
}

export default Artpiece;
