import { Link, useLocation } from 'react-router-dom'

function Nav() {
    const location = useLocation()
    return (
        <div className={`nav ${location.pathname === '/' ? 'nav-home' : ''}`}>
            <div className='desktop-nav'>
                {
                    location.pathname !== '/'
                        ?
                        <Link to='/'>CLS Fine Art <span>Gallery</span></Link>
                        :
                        null
                }
                <ul>
                    <li><Link to='/'>Home</Link></li>
                    <li><Link to='/artists'>Artists</Link></li>
                    <li><Link to='/contact'>Contact</Link></li>
                    <li><Link to='/about'>About</Link></li>
                </ul>

            </div>
            <div className='mobile-nav'>                {
                    location.pathname !== '/'
                        ?
                        <Link to='/'>CLS Fine Art <span>Gallery</span></Link>
                        :
                        null
                }
                <button onClick={() => document.querySelector('.mobile-drop').classList.toggle('active')}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg></button>
                <div className='mobile-drop'>
                    <ul>
                        <li><Link to='/' onClick={() => document.querySelector('.mobile-drop').classList.remove('active')}>Home</Link></li>
                        <li><Link to='/artists' onClick={() => document.querySelector('.mobile-drop').classList.remove('active')}>Artists</Link></li>
                        <li><Link to='/contact' onClick={() => document.querySelector('.mobile-drop').classList.remove('active')}>Contact</Link></li>
                        <li><Link to='/about' onClick={() => document.querySelector('.mobile-drop').classList.remove('active')}>About</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Nav;
