import { useParams, Link, useLocation } from 'react-router-dom'

import { useEffect, useState } from 'react'

function Contact(props) {
  const { artists, art } = props

  const [lastNameOn, setLastName] = useState(true)

  const params = useParams()

  const location = useLocation()

  const locationArray = location.pathname.split('/')

useEffect(() => {
  document.querySelector('.App').scrollTo(0, 0)
})

  const uniqueNationality = () => {
    return artists.map(item => item.nationality)
      .filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => {
        return (a.toUpperCase() < b.toUpperCase()) ? -1 : (a.toUpperCase() > b.toUpperCase()) ? 1 : 0;
      }).map((item, i) => {
        return (
          <Link to={`${item}`} key={`nationality-${i}`}>{item}</Link>
        )
      })
  }

  const allArtists = () => {
    if(lastNameOn){
      return artists.sort((a, b) => {
        return (a.last_name.toUpperCase() < b.last_name.toUpperCase()) ? -1 : (a.last_name.toUpperCase() > b.last_name.toUpperCase()) ? 1 : 0;
      }).map((artist, i) => {
        return (
          <Link to={`/artists/${artist.name}/${artist.id}`} key={`artist-${i}`}>{artist.last_name}</Link>
  
        )
      })
    }
    else{
      return artists.sort((a, b) => {
        return (a.name.toUpperCase() < b.name.toUpperCase()) ? -1 : (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : 0;
      }).map((artist, i) => {
        return (
          <Link to={`/artists/${artist.name}/${artist.id}`} key={`artist-${i}`}>{artist.name}</Link>
  
        )
      })
    }
  }

  const specificArtists = (nationality) => {
    return artists.filter(artist => {
      return artist.nationality === nationality
    }).map((artist, i) => {
      return (
        <Link to={`/artists/${artist.name}/${artist.id}`} key={`unq-artist-${i}`}>{artist.name}</Link>
      )
    })
  }

  const artistWork = (artistID) => {
    return art.filter(artpiece => {
      return artpiece.artist_id === parseInt(artistID, 10)
    }).map(artpiece => {
      return (
        <Link to={`/artpiece/${artpiece.id}`} className='artpiece' key={`artpiece-${artpiece.id}`}>
          <img alt={artpiece.title} src={require(`../assets/art-thumb/${artpiece.image}`)} />
          <h4>{artpiece.title}</h4>
          <p>{params.name}</p>
          <p>{artpiece.year}</p>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M288 32c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L306.7 128 169.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L352 173.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V64c0-17.7-14.3-32-32-32H288zM80 64C35.8 64 0 99.8 0 144V400c0 44.2 35.8 80 80 80H336c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v80c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16h80c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" /></svg>
        </Link>
      )
    })
  }



  return (
    <div className='artists'>
      {
        props.art
          ?
          <>
            <h3>{params.name}</h3>
            {
              locationArray.legnth < 3
                ?
                null
                :
                locationArray.length >= 3
                  ?
                  <Link className='back-btn' to='/artists'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M177.5 98c-8.8-3.8-19-2-26 4.6l-144 136C2.7 243.1 0 249.4 0 256s2.7 12.9 7.5 17.4l144 136c7 6.6 17.2 8.4 26 4.6s14.5-12.5 14.5-22l0-88 288 0c17.7 0 32-14.3 32-32l0-32c0-17.7-14.3-32-32-32l-288 0 0-88c0-9.6-5.7-18.2-14.5-22z"/></svg> Back to artists</Link>
                  :
                  null


            }
            <div className='art-list'>
              {artistWork(params.artistid)}
            </div>
          </>
          :
          <>
            <h1>Artists</h1>
            {
              locationArray.legnth < 3
                ?
                null
                :
                locationArray.length >= 3
                  ?
                  <Link to='/artists' className='back-btn'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M177.5 98c-8.8-3.8-19-2-26 4.6l-144 136C2.7 243.1 0 249.4 0 256s2.7 12.9 7.5 17.4l144 136c7 6.6 17.2 8.4 26 4.6s14.5-12.5 14.5-22l0-88 288 0c17.7 0 32-14.3 32-32l0-32c0-17.7-14.3-32-32-32l-288 0 0-88c0-9.6-5.7-18.2-14.5-22z"/></svg> Back to all artists</Link>
                  :
                  null


            }
            {
              !params.nationality && !params.name
                ?
                <>

                  <h3>Nationality</h3>
                  <div className='artist-list'>
                    {uniqueNationality()}
                  </div>

                  <h3>Individual Artists</h3>
                  <button onClick={() => {setLastName(!lastNameOn)}}>{lastNameOn ? 'Show full name' : 'Show last name only'}</button>
                  

                  <div className='artist-list'>
                    {allArtists()}
                  </div>
                </>
                :
                <>
                  <h3>{params.nationality}</h3>
                  <div className='artist-list'>
                    {specificArtists(params.nationality)}
                  </div>
                </>
            }
          </>
      }
      {() => {return window.scrollTo(0, 0)}}
    </div>
  );
}

export default Contact;
