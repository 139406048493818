import SimpleImageSlider from "react-simple-image-slider";

import {useState} from 'react'

const images = [
  { url: require('../assets/art-big/Artwork/LeonPaintings1.jpg') },
  { url: require('../assets/art-big/Artwork/LeonPaintings2.jpg') },
  { url: require('../assets/art-big/Artwork/LeonPaintings3.jpg') },
  { url: require('../assets/art-big/Artwork/LeonPaintings4.jpg') },
  { url: require('../assets/art-big/Artwork/LeonPaintings5.jpg') },
  { url: require('../assets/art-big/Artwork/LeonPaintings125.jpg') }
]


function Home() {
  let [autoPlay, setAutoPlay] = useState(true)
  return (
    <div className="home">
      <h1>CLS Fine Art <span>Gallery</span></h1>

      <SimpleImageSlider
        bgColor={'transparent'}
        width={'100%'}
        height={'100%'}
        images={images}
        showBullets={false}
        showNavs={true}
        slideDuration={1.0}
        autoPlay={autoPlay}
        autoPlayDelay={5.0}
        onClickNav={() => {
          setAutoPlay(false)
        }}
        style={{
          maxWidth: 1200,
          width: '100%',
          height: '70%',
          position: 'relative',
          marginTop: 44
        }}
      />
    </div>
  );
}

export default Home;
