import './app.scss';
import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import Artists from './components/Artists'
import Nav from './components/Nav';

import { Routes, Route } from 'react-router-dom'
import Axios from 'axios'

import { useState, useEffect } from 'react'
import Artpiece from './components/Artpiece';

function App() {
let [artists, setArtists] = useState([])
let [art, setArt] = useState([])
let [selectedArt, selectArt] = useState(false)

useEffect(() => {
  if (artists.length < 1) {
    Axios.get('/getartists').then(res => {
      setArtists(res.data)
    }).catch(err => {
      console.log(err)
    })

    Axios.get('/getart').then(res => {
      setArt(res.data)
    }).catch(err => {
      console.log(err)
    })
  }
}, [])

  return (
    <div className="App">
      <Nav />
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="artists" element={<Artists artists={artists} />} />
        <Route path="artists/:nationality" element={<Artists artists={artists} />}/>
        <Route path="artists/:name/:artistid" element={<Artists artists={artists}  art={art}/>}/>
        <Route path="artpiece/:id" element={<Artpiece art={art} artists={artists} selectedArt={selectArt} selectArt={selectArt}/>}/>
        <Route path="contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
